// User
export const MS0003 = 'Field must be a valid email';
export const MS0005 = 'Field must be a number';
export const MS0006 = 'Update status successfuly'
export const MS0007 = 'Only alphabets are allowed for this field';
export const MS0008 = 'No Space Allowed';
export const MS0009 = 'Field must be a number and greater than 1';
export const MS0010 = 'Validate Date in MM/DD/YYYY format';
export const MS0011 = 'Field value must be greater than 0';
export const MS0012 = 'Invalid email';

export const MS1001 = 'Field is required';
export const MS1002 = 'Field must be a valid phone number';
export const MS1004 = 'Update profile success';
export const MS1008 = 'Passwords must match.';
export const MS1009 = 'Password changed successfully, please login again after 3 seconds';
export const MS1010 = 'Your account is not allowed to login';
export const MS1011 = 'Your account has not been assigned to the campaign. Please contact the administrator';
export const MS1012 = "The phone number is invalid. Please note that international numbers must be preceded by the country code";
export const MS2001 = 'Delete Success';
export const MS2002 = 'Update Success';
export const MS2003 = 'Create Success';
export const MS2004 = 'Duplicate field';
export const MS2005 = 'Clone Success';
export const MS2006 = 'Send mail success';
export const MS2007 = 'Action Success';
export const MS2009 = "Le numéro de votre carte de crédit est invalide.";
export const MS2010 = "La date d’expiration de votre carte de crédit est invalide.";
export const MS2011 = "Le CVV de votre carte de crédit est invalide.";
export const MS2012 = "Download file fail";
//Campagne
export const MS2008 = 'Please login again in 5 seconds';
// Import Data
export const MS3001 = 'Import success';
export const MS3002 = 'Import fail';

// Export Data
export const MS4001 = 'Export success';
export const MS4002 = 'Export fail';

// Create Password Form
export const MS6002 = 'Passwords must match'
export const MS6003 = 'Create Password Success.';
export const MS6004 = 'Your Password is missing one lowercase';
export const MS6005 = 'Your Password is missing one uppercase';
export const MS6006 = 'Your Password is missing one number';
export const MS6007 = 'Your Password is missing one Symbol';
export const MS6008 = 'Your Password is missing min 8 characters';

// INTRANET
export const MS7001 = "Time start must be less than time end";
export const MS7002 = "Time end must be greater than time start";

// ARCHIVE CAMPAGNE
export const MS8001 = "Archive campagne successfully"
export const MS8002 = "Wipe out data successfully"


export const errorPass = {
    text: 'Your Password is missing ',
    lowercase: 'one lowercase',
    uppercase: 'one uppercase',
    number: 'one number',
    symbol: 'one Symbol',
    min: 'min 8 characters',
};

