import { Const } from "../constants";
import * as _ from "lodash";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import querystring from "querystring";

class DonorHelpers {
    public generateUrl = (data: any, campagne: any, type = "1") => {
        const { formCode, encryptKey } = campagne;
        let lang = "fr";
        let urlFromLang = !!campagne.prodonUrlFr ? campagne.prodonUrlFr : Const.urlFromLangFr;
        if (data && _.lowerCase(data.lang) === "en") {
            urlFromLang = !!campagne.prodonUrlEn ? campagne.prodonUrlEn : Const.urlFromLangEn;
            lang = "en";
        }
        
        if (_.includes(["1", "2"], type)) { // prodon
            const ivKey = encryptKey.substring(_.size(encryptKey) - 16);
            const params = _.omitBy(this.generateData(data, encryptKey, ivKey, type), _.isNil);
            const queryStr = querystring.stringify(params);
            let splitUrl = urlFromLang.split("?");
            let additionnalQueryStr = splitUrl[1] ?? "";
            return `${splitUrl[0]}/${formCode}/?PersonalKey=1&PersonalId=${data.id}&${queryStr}${(!!additionnalQueryStr ? "&" + additionnalQueryStr : "")}`;
        } else if (type === '3') { // moneris
            const paymentByLink = campagne?.formCode || '';
            return `${paymentByLink}/${lang}/payment/${data.id}`;
        }
        return "";
    };

    public generateData = (data: any, encryptKey: string, ivKey: string, type: string) => {
        return {
            FrmCountry: this.encryptValue(encryptKey, ivKey, Const.frmCountry),
            FrmTitle: this.encryptValue(encryptKey, ivKey, data.gender),
            FrmFirstName: this.encryptValue(encryptKey, ivKey, data.first_name),
            FrmLastName: this.encryptValue(encryptKey, ivKey, data.last_name),
            FrmAddress: this.encryptValue(encryptKey, ivKey, data.address),
            FrmCity: this.encryptValue(encryptKey, ivKey, data.city),
            FrmProvinceUID: this.encryptValue(encryptKey, ivKey, this.getProvinceUID(data.province)),
            FrmPostalCode: this.encryptValue(encryptKey, ivKey, data.postal_code),
            FrmEmail: this.encryptValue(encryptKey, ivKey, data.email),
            FrmHome: this.encryptValue(encryptKey, ivKey, data.phone),
            FrmCell: this.encryptValue(encryptKey, ivKey, data.cell),
            FrmReceipt: this.encryptValue(encryptKey, ivKey, Const.frmReceipt),
            FrmTrxType: this.encryptValue(encryptKey, ivKey, type),
            FrmDonorType: this.encryptValue(encryptKey, ivKey, Const.frmDonorType),
            FrmRoadNo: this.encryptValue(encryptKey, ivKey, data.roadno),
            FrmRoadType: this.encryptValue(encryptKey, ivKey, data.roadtype),
            FrmRoad: this.encryptValue(encryptKey, ivKey, data.road),
            FrmPointCallNo: this.encryptValue(encryptKey, ivKey, data.pointcallno),
            trx_monali: this.encryptValue(encryptKey, ivKey, data.id),
        };
    };

    public getProvinceUID = (provinceCode: string) => {
        const province = _.find(Const.provinces, (o: any) => o.name === provinceCode);
        return province && province.value ? _.toString(province.value) : "0";
    };

    public encryptValue = (encryptKey: string, iv: string, input: any) => {
        const str = _.toString(input);
        if (str && !_.isEmpty(str)) {
            const key = CryptoJS.enc.Utf8.parse(encryptKey);
            const options = {
                iv: CryptoJS.enc.Utf8.parse(iv),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.ZeroPadding,
            };
            const cipher = AES.encrypt(str, key, options);
            return CryptoJS.enc.Base64.stringify(cipher.ciphertext);
        }
        return null;
    };

    public decryptValue = (str: string, key: string, iv: string) => {
        if (str && !_.isEmpty(str)) {
            const options = {
                iv: CryptoJS.enc.Utf8.parse(iv),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.ZeroPadding,
            };
            const decode = AES.decrypt(str, key, options);
            return decode.toString(CryptoJS.enc.Utf8);
        }
        return null;
    };
}

export default new DonorHelpers();
