import { v4 } from "uuid";
import * as _ from "lodash";

export const initUser = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    title: "",
    department: "",
    client: null,
    campagne: null,
    role: 3,
    active: 1,
    address: "",
    personalEmail: "",
    birthday: "",
    dateOfEmployment: "",
    computerEnvironment: "",
    secondJob: "",
    fullOrPartTime: "",
    authenWith: "email",
    accessPermisson: null,
    link: "",
};

export const initClient = {
    name: "",
    url: "",
    active: 1,
};

export const initAgent = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    title: "",
    department: "",
    client: null,
    campagne: null,
    role: 3,
    active: 1,
    address: "",
    personalEmail: "",
    birthday: "",
    dateOfEmployment: "",
    computerEnvironment: "",
    secondJob: "",
    fullOrPartTime: ""
};

export const initTemplateField = {
    name: "",
    fr: "",
    en: "",
    type: 'string',
    classStyle: "",
    readOnly: false,
    anonymizeOnArchive: false,
    list: [{id: "", name: ""}]
}

export const initProdonField = {
    name: "",
    fr: "",
    en: "",
    type: '',
    classStyle: "",
    list: [{id: "", name: "", sendMail: false}],
    value: ''
}

export const initLane = {
    id: "",
    title: "",
    display: 1,
    classStyle: "",
    boxVisibility: 2
}

export const initStoryBoardField = {
    id: `card-${_.random(1.2, 5.5)}`,
    name: "Inscrivez un title",
    note: "",
    list: []
}

export const initStoryBoardTextField = {
    name: "",
    type: "text",
    value: "",
    media: null
}

export const initStoryBoardTFileField = {
    name: "",
    type: "image",
    value: "",
    media: null
}

export const initStoryBoardTCheckboxField = {
    name: "",
    type: "checkbox",
    value: "",
    media: null
}

export const initStoryBoardNoteField = {
    name: "note",
    type: "textarea",
    value: "",
    media: null
}

export const initStoryBoardLane = {
    id: v4(),
    title: "",
    classStyle: "",
    cards: [
        {
            id: `card-${_.random(1.2, 5.5)}`,
            name: "",
            note: "",
            list: []
        }
    ]
}

export const initStoryBoardSheet = {
    id: v4(),
    sheet: "",
    content: [
        initStoryBoardLane
    ]
}

const timeStart = new Date();
export const initEvent = {
    title: 0,
    titleOther: "",
    campagne: "",
    segmentScript: "",
    segmentType: "",
    timeStart: new Date(timeStart),
    timeEnd: new Date(timeStart.setHours(new Date(timeStart).getHours() + 1)),
    description: "",
    feedback: "",
    isPause: 0,
    timePause: "0:00",
    user: "",
    confirm: false,
    isVacation: 0
}

export const initPaymentMoneris = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    province: "",
    postal_code: "",
    don_frequence: "",
    don_montant: "",
    start_date: "",
    pan: "",
    expdate: "",
    cvd: ""
}