import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import { icons } from './assets/icons'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';
import client from 'libs/apolloClient';
import { I18nextProvider } from 'react-i18next';
import  i18n from './i18n';
import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';

//redirect without contain payment string in url
if (document.URL.indexOf("payment") === -1 && [
  "avenir.fondationstejustine.org", 
  "sainte-justine.referezunami.com"
].includes(window.location.hostname)) {
  window.location.href = 'https://dons.fondationstejustine.org';
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

React.icons = icons
ReactDOM.render(
  <React.Fragment>
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <App />
        </I18nextProvider>
    </ApolloProvider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
