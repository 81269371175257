import Cookie from "js-cookie";
import { Const } from "../constants";
import * as _ from "lodash";
import jwt from "jwt-decode";

class TransferHelpers {
    public checkAuthen = (req?: any) => {
        let token, expirationDate;
        /**
         * req: for server site when reloading page
         */
        try {
            token = this.getCookie("transjwt", req);
            expirationDate = this.getCookie("expirationDateTransfer", req);
            if (!_.isNull(token) && !_.isUndefined(token) && expirationDate > 0) {
                //let user: IUser = jwt(token);
                /*
                  if outside expirationDate
                  or role is not exits in const
                */
                if (new Date().getTime() > +expirationDate) {
                    //!_.find(Const.userRoles, ['id', +user.role])
                    return false;
                }

                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    public checkRuleWithLogin = (transferToken: any) => {
        try {
            let transfer: any = jwt(transferToken);
            if (transfer) {
                this.setTransferInfo(transferToken);
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    public setTransferInfo = (token: string) => {
        if (token) {
            this.setCookie("transjwt", token); // for ssr
            /* expirationDate: 24hours */
            this.setCookie("expirationDateTransfer", new Date().getTime() + Const.expireDate * 1000);
        }
    };

    public transferLogout = () => {
        this.removeCookie("transjwt");
        this.removeCookie("expirationDateTransfer");
        return true;
    };

    public getToken(name: string) {
        let token;
        token = localStorage.get(name) || null;
        return token;
    }

    public removeLocalStorage(key: string) {
        localStorage.removeItem(key);
    }

    public setToken(name: string, value: any) {
        localStorage.setItem(name, value);
    }

    public setCookie(name: string, value: any) {
        Cookie.set(name, value);
    }

    public getTransferLogin() {
        const token = this.getCookie("transjwt");
        const expirationDate = this.getCookie("expirationDateTransfer");
        if (new Date().getTime() < +expirationDate && !_.isNull(token) && !_.isUndefined(token)) {
            const userLogin = jwt(token);
            return userLogin;
        }
    }

    public getCookie(name: string, req?: any) {
        let value: any;
        if (req) {
            if (!req.headers.cookie) return;

            const jwtCookie = req.headers.cookie.split(";").find((c: any) => c.trim().startsWith(name + "="));

            if (!jwtCookie) return;
            value = jwtCookie.split("=")[1];
        } else {
            value = Cookie.get(name);
        }

        return value;
    }

    public removeCookie(name: string) {
        Cookie.remove(name);
    }
}

export default new TransferHelpers();
