import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Notification {
    public showSuccess = ({message, autoClose = 3000}: any) => toast.success(message, {
        position: "top-right",
        autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
    public showError = ({message, autoClose = 3000}: any) => toast.error(message, {
        position: "top-right",
        autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
    public showWarning = ({message, autoClose = 3000}: any) => toast.warning(message, {
        position: "top-right",
        autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export default new Notification();