const donsTemplate = {
    "id" : "dons",
    "title" : "Dons et promesses",
    "classStyle" : "col-md-4",
    "cards" : [ 
        {
            "id" : "id_dons_type",
            "classStyle" : "col-md-6",
            "name" : "don_type",
            "fr" : "Type",
            "en" : "Type",
            "type" : "dropdown",
            "list" : [ 
                {
                    "id" : "don_credit",
                    "name" : "Don_credit",
                    "sendMail" : true
                }, 
                {
                    "id" : "don_prelevement_bancaire",
                    "name" : "Don_prelevement bancaire",
                    "sendMail" : true
                }, 
                {
                    "id" : "promesse_ecasp",
                    "name" : "Promesse_envoi_courriel_avec_suivi_postal (standard)",
                    "sendMail" : false
                }, 
                {
                    "id" : "promesse_ecssp",
                    "name" : "Promesse_envoi_courriel_sans_suivi_postal (coupon)",
                    "sendMail" : false
                }, 
                {
                    "id" : "promesse_dscep",
                    "name" : "Promesse_donateur_sans_courriel_envoi_postal",
                    "sendMail" : false
                }, 
                {
                    "id" : "promesse_dscaep",
                    "name" : "Promesse_donateur_sans_courriel_aucun_envoi_postal",
                    "sendMail" : false
                }
            ]
        }, 
        {
            "id" : "id_dons_montant",
            "classStyle" : "col-md-6",
            "name" : "don_montant",
            "fr" : "Montant du don",
            "en" : "Montant du don",
            "type" : "price"
        }, 
        {
            "id" : "id_dons_frequence",
            "classStyle" : "col-md-6",
            "name" : "don_frequence",
            "fr" : "Fréquence",
            "en" : "Fréquence",
            "type" : "dropdown",
            "list" : [ 
                {
                    "id" : "Don unique",
                    "name" : "Don unique",
                    "sendMail" : true
                }, 
                {
                    "id" : "Don mensuel",
                    "name" : "Don mensuel",
                    "sendMail" : true
                }, 
                {
                    "id" : "Don annuel",
                    "name" : "Don annuel",
                    "sendMail" : false
                }
            ]
        }, 
        {
            "id" : "id_dons_nb_versement",
            "classStyle" : "col-md-6",
            "name" : "don_nb_versement",
            "fr" : "Nombre de versement",
            "en" : "Nombre de versement",
            "type" : "number"
        }, 
        {
            "id" : "id_engagement_annuel",
            "classStyle" : "",
            "name" : "engagement_annuel",
            "fr" : "Engagement annuel",
            "en" : "Engagement annuel",
            "type" : "radio",
            "list" : [ 
                {
                    "id" : "2ans",
                    "name" : "2 ans"
                }, 
                {
                    "id" : "3ans",
                    "name" : "3 ans"
                }, 
                {
                    "id" : "none",
                    "name" : "none"
                }
            ]
        },
        {
            "id" : "id_receipt",
            "name" : "Receipt",
            "fr" : "Reçu par la poste",
            "en" : "",
            "type" : "checkbox",
            "classStyle" : "col-md-6",
            "list" : [ 
                {
                    "id" : "1",
                    "name" : "Reçu par la poste",
                    "sendMail" : false
                }
            ]
        }
    ]
};

export const prodonTemplate = {
    "id" : "prodon",
    "title" : "Prodon",
    "classStyle" : "col-md-5",
    "cards" : [ 
        {
            "id" : "id_don_unique",
            "classStyle" : "col-md-3",
            "name" : "don_unique",
            "fr" : "Je donne en ligne - Don unique",
            "en" : "Je donne en ligne - Don unique",
            "type" : "don_unique"
        }, 
        {
            "id" : "id_don_recurent",
            "classStyle" : "col-md-3",
            "name" : "don_recurent",
            "fr" : "Je donne en ligne - Don récurent",
            "en" : "Je donne en ligne - Don récurent",
            "type" : "don_recurent"
        }, 
        {
            "id" : "id_send_mail",
            "classStyle" : "col-md-4",
            "name" : "refus",
            "fr" : "Refus - Envoyer courriel avec informations",
            "en" : "Refus - Envoyer courriel avec informations",
            "type" : "send_mail",
            "value" : "_info"
        }, 
        {
            "id" : "id_button_link",
            "classStyle" : "col-md-2",
            "name" : "button_link",
            "fr" : "Button Link",
            "en" : "Button Link",
            "type" : "buttonLink",
            "value" : "https://google.com"
        }, 
        {
            "id" : "id_button_link_2",
            "classStyle" : "col-md-3 prodon-google-url",
            "name" : "google_sheet",
            "fr" : "Google Sheet",
            "en" : "Google Sheet",
            "type" : "buttonLink",
            "value" : "https://docs.google.com"
        }, 
        {
            "id" : "id_button_from_template",
            "classStyle" : "col-md-3",
            "name" : "donor_perfect",
            "fr" : "Donor perfect",
            "en" : "Donor perfect",
            "type" : "button_from_template"
        }
    ]
};

export const traditionTemplate = [
    donsTemplate,
    prodonTemplate
];

export const lotoTemplate = [
    donsTemplate,
    {
        "id": "loto",
        "title" : "Loto",
        "classStyle" : "col-md-3",
        "cards" : [ 
            {
                "id" : "loto_ticket",
                "classStyle" : "col-md-12",
                "name" : "loto_ticket",
                "fr" : "Quantité de billets",
                "en" : "Quantity of tickets",
                "type" : "number"
            }, 
            {
                "id" : "loto_amount",
                "classStyle" : "col-md-12",
                "name" : "loto_amount",
                "fr" : "Valeur totale $",
                "en" : "Total value $",
                "type" : "price"
            }, 
        ]
    },
    prodonTemplate
];

export const loto50Template = [
    {
        "id": "loto",
        "title" : "Loto",
        "classStyle" : "col-md-5",
        "cards" : [ 
            {
                "id" : "loto_date_trans",
                "classStyle" : "col-md-12",
                "name" : "loto_date_trans",
                "fr" : "Date de transaction",
                "en" : "Transaction date",
                "type" : "date"
            }, 
            {
                "id" : "loto_ticket",
                "classStyle" : "col-md-12",
                "name" : "loto_ticket",
                "fr" : "Quantité de billets",
                "en" : "Quantity of tickets",
                "type" : "number"
            }, 
            {
                "id" : "loto_amount",
                "classStyle" : "col-md-12",
                "name" : "loto_amount",
                "fr" : "Valeur totale $",
                "en" : "Total value $",
                "type" : "price"
            }, 
            {
                "id" : "loto_status",
                "classStyle" : "col-md-12",
                "name" : "loto_status",
                "fr" : "Statut",
                "en" : "Status",
                "type" : "dropdown",
                "list" : [ 
                    {
                        "id" : "success",
                        "name" : "Succès"
                    }, 
                    {
                        "id" : "fail",
                        "name" : "Échouer"
                    }
                ]
            }, 
            {
                "id" : "loto_code_de_confirmation",
                "classStyle" : "col-md-12",
                "name" : "loto_code_de_confirmation",
                "fr" : "Code de confirmation",
                "en" : "Confirmation code",
                "type" : "string"
            }, 
        ]
    },
    {
        ...prodonTemplate,
        "classStyle" : "col-md-7"
    }
];

export const monerisTemplate = [ 
    {...donsTemplate, "classStyle": "col-md-6"},
    {
        "id" : "prodon",
        "title" : "Moneris",
        "classStyle" : "col-md-6",
        "cards" : [ 
            {
                "id" : "id_don_moneris",
                "classStyle" : "col-md-3",
                "name" : "moneris",
                "fr" : "Je donne en ligne - Moneris",
                "en" : "Je donne en ligne - Moneris",
                "type" : "moneris"
            }, 
            {
                "id" : "id_send_mail",
                "classStyle" : "col-md-4",
                "name" : "refus",
                "fr" : "Refus - Envoyer courriel avec informations",
                "en" : "Refus - Envoyer courriel avec informations",
                "type" : "send_mail",
                "value" : "_info"
            }
        ]
    }
]

export const templateEmail = [ 
    {
        "don_type" : "promesse",
        "don_frequence" : "Don unique",
        "templateSlug" : "_promesse_unique"
    }, 
    {
        "don_type" : "promesse",
        "don_frequence" : "Don mensuel",
        "templateSlug" : "_promesse_mensuel"
    }, 
    {
        "don_type" : "promesse_de_don",
        "don_frequence" : "Don unique",
        "templateSlug" : "_promesse_de_don_unique"
    }, 
    {
        "don_type" : "promesse_de_don",
        "don_frequence" : "Don mensuel",
        "templateSlug" : "_promesse_de_don_mensuel"
    }
];

export const typeOfStoryBoard = [
    {id: "text", name: "Text"},
    {id: 'checkbox', name: 'Checkbox'},
    {id: 'image', name: 'Image'}
]
export const storyBoard = [
    {
        "id": 'sheet-1',
        "sheet": "Sheet 1",
        "content": [
            {
                "id": "1",
                "title": 'INFORMATIONS',
                "cards": [
                    {
                        "id": "id_lang_1",
                        "name": "INFORMATIONS SUR LA FONDATION ",
                        "note": "fondation@uqo.ca",
                        "list": [
                            {
                                "name": "Nom",
                                "type": "text",
                                "value": "Fondation de l’Université du Québec en Outaouais"
                            },
                            {
                                "name": "Adresse & Téléphone ",
                                "type": "text",
                                "value": "283, boulevard Alexandre-Taché, bureau F-0239 Case postale 1250, succursale Hull Gatineau (Québec) J8X 3X7 819 595-3915"
                            },
                            {
                                "name": "Site Internet ",
                                "type": "text",
                                "value": "www.voirgrandensemble.ca "
                            },
                            {
                                "name": "Social Media ",
                                "type": "text",
                                "value": "https://www.facebook.com/Fondation.UQO/ \n https://twitter.com/fondationuqo \n https://www.linkedin.com/company/fondation-uqo"
                            },
                            {
                                "name": "Checkbox 1",
                                "type": "checkbox",
                                "value": "0"
                            },
                            {
                                "name": "Checkbox 2",
                                "type": "checkbox",
                                "value": "1"
                            },
                            {
                                "name": "Image Type",
                                "type": "image",
                                "value": "",
                                "media": {
                                    "url": "/images/logo.png",
                                    "id": "",
                                    "name": "logo"
                                }
                            },
                            {
                                "name": "Just full box",
                                "type": "text",
                                "value": ""
                            },
                        ]
                    },
                    {
                        "id": "id_lang_2",
                        "name": "INFORMATIONS SUR LA FONDATION ",
                        "note": "fondation@uqo.ca",
                        "list": [
                            {
                                "name": "Nom",
                                "type": "text",
                                "value": "Fondation de l’Université du Québec en Outaouais"
                            },
                            {
                                "name": "Adresse & Téléphone ",
                                "type": "text",
                                "value": "283, boulevard Alexandre-Taché, bureau F-0239 Case postale 1250, succursale Hull Gatineau (Québec) J8X 3X7 819 595-3915"
                            },
                            {
                                "name": "Site Internet ",
                                "type": "text",
                                "value": "www.voirgrandensemble.ca "
                            },
                            {
                                "name": "Social Media ",
                                "type": "text",
                                "value": "https://www.facebook.com/Fondation.UQO/ \n https://twitter.com/fondationuqo \n https://www.linkedin.com/company/fondation-uqo"
                            },
                            {
                                "name": "Just full box",
                                "type": "text",
                                "value": ""
                            },
                        ]
                    }
                ],
                "currentPage": 1
            }
        ]
    },
    {
        "id": "sheet-2",
        "sheet": "Sheet 2",
        "content": [
            {
                "id": "1",
                "title": 'Group Sheet 2',
                "cards": [
                    {
                        "id": "id_lang_2",
                        "name": "lang 2",
                        "note": "This is comment 2",
                        "list": [
                            {
                                "name": "FR",
                                "type": "text",
                                "value": "French"
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export const databaseProgress = [
    {id: "En attente", name: "En attente"},
    {id: "Recu", name: "Recu"},
    {id: "Nettoyer", name: "Nettoyer"},
    {id: "Import/Export", name: "Import/Export"}
]

export const statusMedia = [
    {id: 'Logo', name: 'Logo'},
    {id: 'Images', name: 'Images'},
    {id: 'Signature', name: 'Signature'}
]

export const typeOfClientBoard = [
    {id: "text", name: "Text"},
    {id: 'checkbox', name: 'Checkbox'},
    {id: 'image', name: 'Image'},
    {id: 'link', name: 'Link'}
]