import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './assets/scss/style.scss';
import NotFound from 'components/UI/NotFound';
import { Const } from 'constants/index';

// Loading first
const loading = (
    <div className="d-flex justify-content-center">
        <div className="spinner-border text-success m-5" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
)

// Lazy load - Code splitting
const Admin: any = React.lazy(() => import('./features/Admin'));
const Login: any = React.lazy(() => import('./features/Auth/Login'));
const CommingSoon: any = React.lazy(() => import('./features/Front/ComingSoon'));
const Moneris: any = React.lazy(() => import('./features/Front/Moneris'));
// Forgot password
const ForgotPassword: any = React.lazy(() => import('./features/Auth/ForgotPassword'));
const ConfirmForgotPassword: any = React.lazy(() => import('./features/Auth/ConfirmForgotPassword'));
const CreatePassword: any = React.lazy(() => import('./features/Auth/CreatePassword'));
const Redirect: any = React.lazy(() => import('./features/Front/Redirect'));    

function App() {
    return (
        <div className="app">
            <React.Suspense fallback={loading}>
                <BrowserRouter>
                    {/* <Header /> */}
                    <Switch>
                        {/* HOME PAGE */}
                        <Route exact path="/" component={CommingSoon} />
                        <Route exact path={`${Const.localesString}/payment/:idDonor?`} render={props => <Moneris {...props}/>} />
                        {/* LOGIN PAGE */}
                        <Route exact path={`${Const.localesString}/auth/login`} render={props => <Login {...props}/>} />
                        <Route exact path={`${Const.localesString}/auth/login/:url_encoded`} render={props => <Login {...props}/>} />
                        <Route exact path={`${Const.localesString}/auth/forgot-password`} render={props => <ForgotPassword {...props}/>} />
                        <Route exact path={`${Const.localesString}/auth/confirm-forgot-password`} render={props => <ConfirmForgotPassword {...props}/>} />
                        <Route exact path={`${Const.localesString}/auth/create-password/:id`} render={props => <CreatePassword {...props}/>} />

                        <Route exact path={`${Const.localesString}/:url_encode/clients/mod/:id`} render={props => <Redirect {...props} type="mod"/>}/>
                        <Route exact path={`${Const.localesString}/:url_encode/clients/search/`} render={props => <Redirect {...props} type="search"/>} />
                        {/* ADMIN DASHBOARD */}
                        {/* <Route path="/admin" render={props => <Admin {...props} />} /> */}
                        <Route path={`${Const.localesString}/admin`} render={props => <Admin {...props}/>} />
                        <Route component={NotFound} />
                    </Switch>
                </BrowserRouter>
            </React.Suspense>
        </div>
    );
}

export default App;