export const serverUrl = process.env.REACT_APP_API_ENDPOINT || "https://yoda-dev.referezunami.com";
export const tokenPrefix = "x-access-token ";
export const prefix = "/graphql";
export const defaultFileType = 1;
export const limitRecord = 10;
// expire token
export const expireDate = 2 * 3600 * 24;

/*language*/
export const localesString = "/:lng(fr|en)?";
export const defaultLng = "fr";
export const locates = ["fr", "en"];

export const GroupDonor = [
    {
        id: 1,
        name: "Profil",
    },
    {
        id: 2,
        name: "Coordonnées",
    },
    {
        id: 3,
        name: "Historique de don",
    },
    {
        id: 4,
        value: "Autre",
    },
];

// Roles
export const roles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Supervisor" },
    { id: 3, name: "Agent" },
    { id: 4, name: "Intern" },
    { id: 5, name: "Client" },
];

export const accessPermisson = [
    { id: '/admin/logs', name: 'Logs' }
];

export const EmailSaiyan = ["dev@groupemonali.com"];
export const phoneNumberMask = [/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
export const mmyyNumberMask = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const action = {
    ACTION_DELETE: "DELETE",
    ACTION_UPDATE: "UPDATE",
    ACTION_CONFIRM: "CONFIRM",
    ACTION_DELETE_MULTI: "DELETE MULTIPLE",
    ACTION_IMPORT: "IMPORT_EXCEL",
    ACTION_EXPORT: "EXPORT_EXCEL",
    ACTION_CLONE: "CLONE_TEMPLATE",
    ACTION_CONFIG_EMAIL: "ACTION_CONFIG_EMAIL",
    ACTION_SHOW_COMMENT: "SHOW_COMMENT",
    ACTION_ADD_FIELD: "ACTION_ADD_FIELD",
    ACTION_ARCHIVE_CAMPAGNE: "ACTION_ARCHIVE_CAMPAGNE",
    ACTION_WIPE_OUT_DATA: "ACTION_WIPE_OUT_DATA",
};

export const OmitCommonFields = ["id", "type", "createdAt", "updatedAt", "__typename"];

export const headerNav = [
    { path: "/admin/dashboard", name: "Dashboard" },
    { path: "/admin/users", name: "Users" },
    { path: "/admin/clients", name: "Clients" },
    { path: "/admin/privileges", name: "Privileges" },
    { path: "/admin/logs", name: "Logs" },
];

// type of template
export const typeInput = [
    { id: "string", name: "String" },
    { id: "radio", name: "Radio" },
    { id: "checkbox", name: "Checkbox" },
    { id: "phoneNumber", name: "Phone Number" },
    { id: "dropdown", name: "Dropdown" },
    { id: "number", name: "Number" },
    { id: "date", name: "Date" },
    { id: "price", name: "Price" },
    { id: "email", name: "Email" },
    { id: "buttonLink", name: "Button Link" },
];

// type of Prodon
export const typeDons = [
    { id: "string", name: "String" },
    { id: "radio", name: "Radio" },
    { id: "checkbox", name: "Checkbox" },
    { id: "dropdown", name: "Dropdown" },
    { id: "number", name: "Number" },
    { id: "price", name: "Price" },
    { id: "date", name: "Date" }
];

// type of prodon template
export const typeProdon = [
    { id: "buttonLink", name: "Button Link" }, //static link
    { id: "button_from_template", name: "Button From Template" }, // link dynamic for every donor
    { id: "send_mail", name: "Send Mail" },
    { id: "don_unique", name: "Don unique" },
    { id: "don_recurent", name: "Don recurent" },
    { id: "moneris", name: "Moneris" },
];
// type with List
export const inputListWith = ["radio", "checkbox", "dropdown"];

// type with value input
export const inputValueWith = ["buttonLink", "send_mail"];

export const typeLaneDisplay = [
    { id: 1, name: "Form" },
    { id: 2, name: "Show table" },
];

export const boxVisibility = [
    { id: 1, name: 'Expand' },
    { id: 2, name: 'Collapse' },
]
export const donorDisabledFields = ["id_cust", "id_monali"];

export const donsDisableFields = ["don_type", "don_frequence", "don_montant", "don_nb_versement", "engagement_annuel"];

export const campagneLogFields = ["name", "slug", "template", "client", "encryptKey", "formCode", "prodonTemplate", "templateEmail", "active"];

export const userLogFields = ["firstName", "lastName", "username", "campagne", "parent", "title", "department", "phone", "active"];

export const timesheetLogFields = [
    "title",
    "titleOther",
    "timeStart",
    "timeEnd",
    "isPause",
    "timePause",
    "isVacation",
    "client",
    "campagne",
    "segmentScript",
    "feedback",
    "confirm",
    "description",
    "user",
];

export const frmCountry = "Canada";
export const frmPaiUID = "261";
export const frmReceipt = "1";
export const frmDonorType = "0";
export const urlFromLangFr = "https://www.jedonneenligne.org";
export const urlFromLangEn = "https://www.imakeanonlinedonation.org";

export const provinces = [
    { value: 1, name: "QC" },
    { value: 2, name: "ON" },
    { value: 3, name: "SK" },
    { value: 4, name: "AB" },
    { value: 5, name: "BC" },
    { value: 6, name: "PE" },
    { value: 7, name: "MB" },
    { value: 8, name: "NB" },
    { value: 9, name: "NS" },
    { value: 10, name: "NU" },
    { value: 11, name: "NL" },
    { value: 12, name: "NT" },
    { value: 13, name: "YT" },
];

export const exportBy = [
    { id: "by_id", name: "Id MONALI" },
    { id: "by_weekly", name: "Transaction" },
    { id: "by_log_address", name: "Logs of Address" },
    { id: "by_moneris", name: "Moneris" },
];

export const apiKeyTinyMCE = "j9k3nsq0l2a97g3ag14w9zcbvccv3wwo3bnzgnzcx35w9gt5";

export const typeOfCampagne = [
    { id: 1, name: "Reactivation in % of donations" },
    { id: 2, name: "Retention in % of donations" },
    { id: 3, name: "Regular" },
];

export const weeklyFields = [
    "agent_name",
    "date",
    "id_cust",
    "cust_id",
    "first_name",
    "last_name",
    "salutation",
    "salutation_titre",
    "address",
    "address1",
    "city",
    "postal_code",
    "code_media",
    "segment_script",
    "don_type",
    "don_montant",
    "don_frequence",
    "receipt",
];

export const fullOrPartTimes = [
    { id: 1, name: "Full Time" },
    { id: 2, name: "Part Time" },
];
export const defaultBgColorNoti = "#f0662f";
export const defaultColorNoti = "#fff";

export const statusOfCampagne = [
    { id: 1, name: "Preperation" },
    { id: 2, name: "In progress" },
    { id: 3, name: "Completed" },
    { id: 4, name: "Archived" },
    { id: 5, name: "Fin de campagne" }
];

export const levelOfSupport = [
    { id: 1, name: "High" },
    { id: 2, name: "Medium" },
    { id: 3, name: "Low" },
];

export const defaultShowingDropdown = [10, 25, 50, 100];

export const monthEN = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
export const monthFR = ["JAN", "FÉV", "MAR", "AVR", "MAI", "JUN", "JUL", "AOÛ", "SEP", "OCT", "NOV", "DEC"];

export const terminationGroups = [
    { id: 1, name: "Success" },
    { id: 2, name: "Relance" },
    { id: 3, name: "Refus" },
    { id: 4, name: "DNC" },
    { id: 5, name: "Autres" },
];

export const timeIntervals = 5;

export const titleEvents = [
    { id: 1, name: "Call" },
    { id: 2, name: "Admin" },
    { id: 3, name: "Training" },
    { id: 4, name: "Other" },
    { id: 5, name: "Planned" },
];

export const exportTimeFields = [
    {
        id: "firstName",
        name: "First Name",
    },
    {
        id: "lastName",
        name: "Last Name",
    },
    {
        id: "email",
        name: "Email",
    },
    {
        id: "client",
        name: "Clients",
    },
    {
        id: "campagne",
        name: "Campagnes",
    },
    {
        id: "segmentScript",
        name: "Segments Script",
    },
    {
        id: "totalPlannedTimes",
        name: "Total Planned Times",
    },
    {
        id: "totalWorkTimesConfirm",
        name: "Total Time Confirmed",
    },
    {
        id: "totalWorkTimesUnConfirm",
        name: "Total Time Unconfirmed",
    },
    {
        id: "perContactComplete",
        name: "Contacts complétés",
    },
    {
        id: "perContactParHR",
        name: "Contact par heure",
    },
    {
        id: "perRevenus",
        name: "Revenus",
    },
    {
        id: "perRevenuGlobal",
        name: "Revenu global",
    },
    {
        id: "perRevenuParCC",
        name: "Revenu par cc",
    },
    {
        id: "perRevenuGlobalParCC",
        name: "Revenu global par cc",
    },
    {
        id: "perRevenuParHR",
        name: "Revenu par HR",
    },
    {
        id: "perRevenuGlobalParHR",
        name: "Revenu global par HR",
    },
    {
        id: "terCCTotal",
        name: "Succès Carte de crédit et PB",
    },
    {
        id: "terCc_promesses",
        name: "Succès global"
    },
    {
        id: "terCCPBParCC",
        name: "Taux de succès CC PB"
    },
    {
        id: "terGlobalParCC",
        name: "Taux de succès global"
    },
    {
        id: "perDonMoyen",
        name: "Don moyen"
    }
];

export const listTabPaneCalendar = [
    { name: "month", value: "dayGridMonth" },
    { name: "week", value: "timeGridWeek" },
    { name: "day", value: "timeGridDay" },
];

export const defaultActiveViewCalendar = "timeGridWeek";

export const campagnesTestId = ["623c738670e46f3666b137a9", "61010b94df49b246aee348d7"];

export const languages = [
    { name: 'Français', id: 'fr' },
    { name: 'English', id: 'en' },
];

export const billingMethodTypes = [
    { name: "Cost per call", value: 1 },
    { name: "Percentage of total donations", value: 2 },
    { name: "Actual donations", value: 3 },
]

export const jwtSecretKey = "123@123";

export const campagneType = [
    { id: 1, name: 'Traditional' },
    { id: 2, name: 'Loto'},
    { id: 4, name: 'Loto 50/50'},
    { id: 3, name: 'Moneris' }
];
export const timezone = "America/Toronto";
export const siteKeyGGCaptchaV3 = "6LeeAs0iAAAAADluK-Zq4uGt8tcoQs9Mn6SpwD7z";
export const periodIsInDays = "2023-01-16";